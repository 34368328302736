<template>
  <div>
    <v-overlay :value="loading" class="align-center justify-center">
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar v-model="error.show">
      <p class="py-0 my-0 font-weight-black">Ops, falhou!</p>
      {{ error.message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="error.show = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>

    <v-toolbar flat v-if="!loading" style="background-color: #f3f3f3">
      <v-spacer />

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="grey"
            dark
            class="mb-2 ml-2"
            v-bind="attrs"
            v-on="on"
            @click="voltarDashboard()">
            <v-icon small> mdi-arrow-left </v-icon>
          </v-btn>
        </template>
        <span>Voltar ao dashboard</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="grey"
            dark
            class="mb-2 ml-2"
            v-bind="attrs"
            v-on="on"
            @click="atualizar()">
            <v-icon small> mdi-refresh </v-icon>
          </v-btn>
        </template>
        <span>Atualizar faturas</span>
      </v-tooltip>

    </v-toolbar>

    <v-container class="ma-0" fluid>
      <v-data-table
        :headers="headers"
        :items="faturas"
        sort-by="nome"
        class="elevation-1"
        :loading="loadingLista"
        loading-text="Carregando..."
        @dblclick:row="($event, { item }) => acessarFatura(item)"
        :footer-props="{
          'items-per-page-options': [12, 24, 36, 48, 60, 100, -1],
        }"
        :items-per-page="12"
        locale="pt"
        item-key="data">
        <template v-slot:item.dateCreated="{ item }">
          <span>{{
            new Date(`${item.dateCreated}T00:00:00`).toLocaleDateString('pt-BR', {timeZone: 'UTC'})
          }}</span>
        </template>

        <template v-slot:item.value="{ item }">
          <span>{{
            item.value.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          }}</span>
        </template>

        <template v-slot:item.status="{ item }">
          <span
            >{{ status[item.status] }}
            {{
              item.paymentDate
                ? "em " +
                  new Date(`${item.paymentDate}T00:00:00`).toLocaleString(
                    "default",
                    { day: "2-digit", month: "2-digit" }
                  )
                : ""
            }}</span
          >
        </template>

        <template v-slot:item.opcoes="{ item }">
          <v-tooltip bottom>
            <template
              v-if="status[item.status] !== 'Pago'"
              v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                @click="pagar(item.invoiceUrl)">
                mdi-credit-card
              </v-icon>
            </template>
            <span>Pagar</span>
          </v-tooltip>
        </template>

        <template v-slot:item.dueDate="{ item }">
          <span>{{
            new Date(`${item.dueDate}T00:00:00`).toLocaleDateString('pt-BR', {timeZone: 'UTC'})
          }}</span>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import {
  fetchApiClientes,
  asaasListarFaturas,
  asaasCriarFatura,
} from "../../services/api";
import { verificarSessao, getMes } from "../../utils/utils";

export default {
  name: "Faturas",
  data() {
    return {
      loading: false,
      loadingLista: true,
      error: {
        show: false,
        message: "",
        title: "",
      },
      headers: [
        { text: "Data", value: "dateCreated" },
        { text: "Descrição", value: "description" },
        { text: "Valor da fatura", value: "value" },
        { text: "Vencimento", value: "dueDate" },
        { text: "Status", value: "status" },
        { text: "Opções", value: "opcoes", sortable: false },
      ],
      faturas: [],
      status: {
        RECEIVED: "Pago",
        CONFIRMED: "Pago",
        RECEIVED_IN_CASH: "Pago",
        AWAITING_RISK_ANALYSIS: "Pendente",
        PENDING: "Pendente",
        OVERDUE: "Atrasado",
        REFUND_REQUESTED: "Estornado",
        REFUND: "Estornado",
        REFUNDED: "Estornado",
      },
      token: localStorage.getItem("token"),
      asaas_id_revenda: this.$route.params.asaas_id_revenda,
    };
  },
  async created() {
    verificarSessao();
    setTimeout(verificarSessao, 5 * 60000);
    /**
     *
     */
    window.addEventListener("offline", () => (this.loading = true));
    window.addEventListener("online", () => (this.loading = false));
    /**
     *
     */
    await this.gerarCobranca();
  },
  methods: {
    setError(error) {
      this.loading = false;
      this.error = {
        show: true,
        message: error ? error : "Falha não identificada, tente novamente.",
      };
    },
    async atualizar() {
      this.loadingLista = true;
      return asaasListarFaturas(this.token, this.asaas_id_revenda)
        .then((response) => {
          this.loadingLista = false;
          this.faturas = response?.data?.faturas?.data;
        })
        .catch((error) => {
          this.loadingLista = false;
          this.setError(error);
        });
    },
    acessarFatura(fatura) {
      window.open(fatura.invoiceUrl);
    },
    /**
     *
     */
    async gerarCobranca() {
      this.loading = true;

      try {
        const [clientes, faturas] = await Promise.all([
          fetchApiClientes(this.token),
          asaasListarFaturas(this.token, this.asaas_id_revenda),
        ]);

        const ultimaFatura = faturas?.data?.faturas?.data?.shift();
        if (!ultimaFatura) {
          throw new Error("A última fatura não foi encontrada.");
        }

        const mesUltimaFatura = new Date(
          `${ultimaFatura.dueDate}T00:00:00`
        ).toLocaleString("default", { month: "2-digit", year: "2-digit" });
        const mesAtual = new Date().toLocaleString("default", {
          month: "2-digit",
          year: "2-digit",
        });
        if (mesUltimaFatura === mesAtual) {
          throw new Error("A última fatura já foi gerada pra revenda.");
        }

        let total = {
          valor: 0,
          lojas: 0,
          lojas_demos: 0,
          lojas_bloqueadas: 0,
          nfces: 0,
          nfes: 0,
          gamazaps: 0,
          gamazaps_campanhas: 0,
          robos: 0,
          consultorias_estrategicas: 0,
        };

        clientes?.data?.clientes?.ativos.map((cliente) => {
          if (/demo/gi.test(cliente.responsavel)) {
            total.lojas_demos++;
            return;
          }

          if (cliente.block === "🔴") {
            total.lojas_bloqueadas++;
            return;
          }

          const mesCadastro = new Date(
            `${cliente.data_cadastro}T00:00:00`
          ).toLocaleString("default", { month: "2-digit", year: "2-digit" });
          if (mesCadastro === mesAtual) {
            return;
          }

          total.lojas++;
          total.valor += this.valorRevenda;

          if (!cliente.analytics) {
            return;
          }

          const nfces = parseInt(cliente?.analytics?.fiscal60dias ?? 0);
          if (nfces > 0) {
            total.nfces += nfces;
            total.valor += nfces * this.valorNfce;
          }

          const nfes = parseInt(cliente?.analytics?.nfes60dias ?? 0);
          if (nfes > 0) {
            total.nfes += nfes;
            total.valor += nfes * this.valorNfe;
          }

          if (cliente.analytics[`modulos_mes${getMes(-1)}`]) {
            const gamazaps = parseInt(
              cliente.analytics[`modulos_mes${getMes(-1)}`].gamazap ?? 0
            );
            if (gamazaps > 0) {
              total.gamazaps += gamazaps;
              total.valor += gamazaps * this.valorGamaZap;
            }

            const gamazaps_campanhas = parseInt(
              cliente.analytics[`modulos_mes${getMes(-1)}`].gamazap_campanhas ??
                0
            );
            if (gamazaps_campanhas > 0) {
              total.gamazaps_campanhas += gamazaps_campanhas;
              total.valor += gamazaps_campanhas * this.valorGamaZapCampanhas;
            }

            const robos = parseInt(
              cliente.analytics[`modulos_mes${getMes(-1)}`].robo ?? 0
            );
            if (robos > 0) {
              total.robos += robos;
              total.valor += robos * this.valorRobo;
            }

            const consultorias_estrategicas = parseInt(
              cliente.analytics[`modulos_mes${getMes(-1)}`].consultoria_estrategica ?? 0
            );
            if (consultorias_estrategicas > 0) {
              total.consultorias_estrategicas += consultorias_estrategicas;
              total.valor += consultorias_estrategicas * this.valorConsultoriaEstrategica;
            }
          }
        });

        let descricao = "";
        descricao +=
          total.lojas < 5
            ? `${total.lojas}/5 lojas mínimas / `
            : `${total.lojas} lojas / `;

        if (total.lojas_demos > 0) {
          descricao += `${total.lojas_demos} lojas de demo / `;
        }

        if (total.lojas_bloqueadas > 0) {
          descricao += `${total.lojas_bloqueadas} lojas bloqueadas / `;
        }

        if (total.nfces > 0) {
          descricao += `${total.nfces} NFC-e emitidas / `;
        }

        if (total.nfes > 0) {
         descricao += `${total.nfes} NF-e recebidas / `;
        }

        if (total.gamazaps > 0) {
          descricao += `${total.gamazaps} WhatsApp Conectados / `;
        }

        if (total.gamazaps_campanhas > 0) {
          descricao += `${total.gamazaps_campanhas} WhatsApp Marketing / `;
        }

        if (total.robos > 0) {
          descricao += `${total.robos} Robôs de atendimento`;
        }

        if (total.consultorias_estrategicas > 0) {
          descricao += `${total.consultorias_estrategicas} Consultorias Estratégicas`;
        }

        if (total.lojas < 5) {
          total.valor = 200;
        }

        if (this.isDesenvolvimento) {
          descricao += ` (DEV)`;
          total.valor = 100;
        }

        const criarFatura = await asaasCriarFatura(this.token, {
          billingType: "BOLETO",
          interest: { value: 1 },
          fine: { value: 2, type: "PERCENTAGE" },
          customer: this.asaas_id_revenda,
          dueDate: `${new Date().getFullYear()}-${getMes()}-10`,
          value: total.valor,
          description: descricao,
          split: [
            {
              walletId: (this.isDesenvolvimento ? "7171bb1c-f2d2-4729-bf08-ec90431ccaf5" : "ec7ac8c2-9e1f-44c9-9d6e-bae9088523ea"),
              percentualValue: 5,
            },
          ],
        });
        if (!criarFatura) {
          throw new Error(
            "Não foi possível gerar a fatura do mês pra revenda."
          );
        }

        this.loading = false;
      } catch (err) {
        console.error(err.message);
        this.loading = false;
      }

      await this.atualizar();
    },
    /**
     *
     */
    voltarDashboard() {
      this.$router.push({ path: "/dashboard/" });
    },
    /**
     *
     */
    pagar(url) {
      window.open(url);
    },
  },
};
</script>

<style>
body {
  margin: 15px !important;
}
</style>
