var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-overlay',{staticClass:"align-center justify-center",attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"64"}})],1),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.error.show = false}}},'v-btn',attrs,false),[_vm._v(" Fechar ")])]}}]),model:{value:(_vm.error.show),callback:function ($$v) {_vm.$set(_vm.error, "show", $$v)},expression:"error.show"}},[_c('p',{staticClass:"py-0 my-0 font-weight-black"},[_vm._v("Ops, falhou!")]),_vm._v(" "+_vm._s(_vm.error.message)+" ")]),(!_vm.loading)?_c('v-toolbar',{staticStyle:{"background-color":"#f3f3f3"},attrs:{"flat":""}},[_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2 ml-2",attrs:{"color":"grey","dark":""},on:{"click":function($event){return _vm.voltarDashboard()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-arrow-left ")])],1)]}}],null,false,3454457527)},[_c('span',[_vm._v("Voltar ao dashboard")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2 ml-2",attrs:{"color":"grey","dark":""},on:{"click":function($event){return _vm.atualizar()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-refresh ")])],1)]}}],null,false,3557955326)},[_c('span',[_vm._v("Atualizar faturas")])])],1):_vm._e(),_c('v-container',{staticClass:"ma-0",attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.faturas,"sort-by":"nome","loading":_vm.loadingLista,"loading-text":"Carregando...","footer-props":{
        'items-per-page-options': [12, 24, 36, 48, 60, 100, -1],
      },"items-per-page":12,"locale":"pt","item-key":"data"},on:{"dblclick:row":function ($event, ref) {
            var item = ref.item;

            return _vm.acessarFatura(item);
}},scopedSlots:_vm._u([{key:"item.dateCreated",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(((item.dateCreated) + "T00:00:00")).toLocaleDateString('pt-BR', {timeZone: 'UTC'})))])]}},{key:"item.value",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.value.toLocaleString("pt-br", { style: "currency", currency: "BRL", })))])]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.status[item.status])+" "+_vm._s(item.paymentDate ? "em " + new Date(((item.paymentDate) + "T00:00:00")).toLocaleString( "default", { day: "2-digit", month: "2-digit" } ) : ""))])]}},{key:"item.opcoes",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([(_vm.status[item.status] !== 'Pago')?{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.pagar(item.invoiceUrl)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-credit-card ")])]}}:null],null,true)},[_c('span',[_vm._v("Pagar")])])]}},{key:"item.dueDate",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(((item.dueDate) + "T00:00:00")).toLocaleDateString('pt-BR', {timeZone: 'UTC'})))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }